import nookies from 'nookies';

const list = ['A', 'B'];
export const AB_TEST_KEY = 'AB_TEST_VALUE';

const cookieOption = {
  maxAge: 360 * 24 * 60 * 60,
  path: '/',
  // domain: 'textin.com',
};

export function setABTest(ctx?: any) {
  let val = '';
  try {
    if (typeof global === 'object') {
      val = getABTest(ctx);
      if (!val) {
        const preTest = global[AB_TEST_KEY];
        let curValue = list[0];
        if (preTest) {
          const preIndex = list.findIndex((item) => item === preTest);
          if (preIndex !== -1 && preIndex < list.length - 1) {
            curValue = list[preIndex + 1];
          }
        }
        global[AB_TEST_KEY] = curValue;
        nookies.set(ctx, AB_TEST_KEY, curValue, cookieOption);
        val = curValue;
      }
    }
  } catch (error) {
    console.log('setABTest error', error);
  }
  return val;
}

export function getABTest(ctx?: any) {
  return nookies.get(ctx)[AB_TEST_KEY] || '';
}
